import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import expenses from './modules/expenses'
import user from "@/state/modules/user";

Vue.use(Vuex);

export default function createStore(domain, auth0) {
    return new Store({
        modules: {
            expenses: expenses(domain),
            user: user(domain.authorizationService, auth0)
        },
    });
}
