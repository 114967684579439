const VIEW_CATEGORIES_ROLE = 'ViewCategories';
const VIEW_EXPENSES_ROLE = 'ViewExpenses';
const MANAGE_EXPENSES_ROLE = 'ManageExpenses';

function hasRole(role, roles = null) {
    return roles === null || roles.includes(role);
}

export default class AuthorizationService {
    canViewCategories(roles) {
        return hasRole(VIEW_CATEGORIES_ROLE, roles);
    }

    canViewExpenses(roles) {
        return hasRole(VIEW_EXPENSES_ROLE, roles);
    }

    canManageExpenses(roles) {
        return hasRole(MANAGE_EXPENSES_ROLE, roles);
    }
}
