function parseJwt (token)
{
    try
    {
        let base64Token = token.split('.')[1].replace('-', '+').replace('_', '/');

        return JSON.parse(
            window.atob(base64Token)
        );
    }
    catch
    {
        return null;
    }
}

export default function user (
    authorizationService, 
    auth0
)
{
    return {

        getters : {

            isLoggedIn ()
            {
                return auth0.isAuthenticated;
            },

            username ()
            {
                return auth0.getLoggedInUserName();
            },

            async canViewCategories ()
            {
                const token = parseJwt(await auth0.getTokenSilently());
                return authorizationService.canViewCategories(token?.permissions);
            },

            async canViewExpenses ()
            {
                const token = parseJwt(await auth0.getTokenSilently());
                return authorizationService.canViewExpenses(token?.permissions);
            },

            async canManageExpenses ()
            {
                const token = parseJwt(await auth0.getTokenSilently());
                return authorizationService.canManageExpenses(token?.permissions);
            }
        },

        namespaced : true
    };
}
