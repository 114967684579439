import Vue from 'vue';
import VueRouter from 'vue-router';

import ManageExpenses from '../components/ManageExpenses.vue'
import Forbidden from '../components/Forbidden.vue'
import Dashboard from '../components/Dashboard.vue';
import Home from '../components/Home.vue'

import { authGuard } from "@/routing/guards/authGuard";
import canViewExpenses from "@/routing/guards/can-view-expenses";

Vue.use(VueRouter);

export default function createRouter (store) {

    const router = new VueRouter({
        routes: [
            {
                path: "/",
                name: "Home",
                component: Home,
            },
            {
                path: "/dashboard",
                name: "Dashboard",
                component: Dashboard,
                beforeEnter: canViewExpenses(store)
            },
            {
                path: "/expenses",
                name: "ManageExpenses",
                component: ManageExpenses,
                beforeEnter: canViewExpenses(store)
            },
            {
                path: '/forbidden',
                meta: {
                    isPublic: true
                },
                component: Forbidden
            },
        ]
    });

    // Authorize.
    router.beforeEach(
        authGuard
    );

    return router;
}