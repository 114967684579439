import * as axios from 'axios';

export default class ExpensesApiClient
{
    constructor(uri) {
        this.client = axios.create({
            baseURL : uri,
            timeout : 10000,
            validateStatus : false,
            responseType: 'json'
        });
    }

    async get (endpoint, headers = {}, query = {}) {
        const response = await this.client.get(endpoint, {
            params  : query,
            headers : headers
        });

        switch (response.status) {
            case 200:
                return {
                    status : response.status,
                    data   : response.data
                };

            case 401:
            case 403:
                return {
                    status : response.status,
                    errors : response.data.Fault.Errors
                };

            default:
                throw new Error(`Expenses API GET request to ${endpoint} failed with status code ${response.status} and payload ${response.data}`);
        }
    }

    async post (endpoint, headers = {}, payload = {}) {
        const response = await this.client.post(endpoint, payload, {
            headers : headers
        });

        switch (response.status) {
            case 201:
                return {
                    status : response.status,
                    data   : response.data
                };

            case 400:
            case 401:
            case 403:
                return {
                    status : response.status,
                    errors : response.data.Fault.Errors
                };

            default:
                throw new Error(`Expenses API POST request to ${endpoint} failed with status code ${response.status} and payload ${response.data}`);
        }
    }

    async put (endpoint, headers = {}, payload = {}) {
        const response = await this.client.put(endpoint, payload, {
            headers : headers
        });

        switch (response.status) {
            case 200:
                return {
                    status : response.status,
                    data   : response.data
                };

            case 400: // TODO(pedrolo): pass through error message to be displayed in banner
            case 401:
            case 403:
            case 404:
                return {
                    status : response.status,
                    errors : response.data.Fault.Errors
                };

            default:
                throw new Error(`Expenses API PUT request to ${endpoint} failed with status code ${response.status} and payload ${response.data}`);
        }
    }

    async delete (endpoint, headers = {}) {
        const response = await this.client.delete(endpoint, {
            headers : headers
        });

        switch (response.status) {
            case 200:
                return {
                    status : response.status,
                    data   : response.data
                };

            case 401:
            case 403:
                return {
                    status : response.status,
                    errors : response.data.Fault.Errors
                };

            default:
                throw new Error(`Expenses API DELETE request to ${endpoint} failed with status code ${response.status} and payload ${response.data}`);
        }
    }
}
