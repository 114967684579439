<template>
  <div>
    <b-spinner class="spinner"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
}
</script>

<style scoped>

.spinner {
  margin-top: 25%;
  width: 3rem;
  height: 3rem;
}

</style>