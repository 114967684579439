export default function canViewExpenses (store)
{
    return (to, from, next) =>
    {
        if (store.getters['user/canViewExpenses'])
        {
            next();
        }
        else
        {
            next('/forbidden');
        }
    };
}
