import { getInstance } from '../../auth/index';

export default class ExpensesRepository {
    constructor (expensesApiClient)
    {
        this.expensesApiClient = expensesApiClient;
    }

    async getExpenses () {
        const token = await getInstance().getTokenSilently();

        const result = await this.expensesApiClient.get('/expenses',
            {
              Authorization: `Bearer ${token}`
              // TODO(pedrolo): Origin will need to be added, should be based on the env
            }
          );

          if (result.status !== 200) {
              return {
                  errors : result.data
              }
          }

        return {
            expenses : result.data.Expenses
        };
    }

    async getCategories () {
        const token = await getInstance().getTokenSilently();

        const result = await this.expensesApiClient.get('/categories',
            {
                Authorization: `Bearer ${token}`
                // TODO(pedrolo): Origin will need to be added, should be based on the env
            }
        );

        if (result.status !== 200) {
            return {
                errors : result.data
            }
        }

        return {
            categories : result.data.Categories
        };
    }

    async updateExpense (expense) {
        const token = await getInstance().getTokenSilently();

        const result = await this.expensesApiClient.put(`/expenses/${expense.Id}`,
            {
                Authorization: `Bearer ${token}`
            },
            expense
        );

        if (result.status !== 200) {
            return {
                errors : result.errors
            };
        }

        return {
            expense : result.data
        };
    }

    async createExpense (expense) {
        const token = await getInstance().getTokenSilently();

        const result = await this.expensesApiClient.post('/expenses',
            {
                Authorization: `Bearer ${token}`
            },
            expense
        );

        if (result.status !== 201) {
            return {
                errors : result.errors
            };
        }

        return {
            expense : result.data
        };
    }

    async deleteExpense (expenseId) {
        const token = await getInstance().getTokenSilently();

        const result = await this.expensesApiClient.delete(`/expenses/${expenseId}`,
            {
                Authorization: `Bearer ${token}`
            }
        );

        if (result.status !== 200) {
            return {
                errors : result.errors
            };
        }

        return {};
    }
}
