<template>
  <div>
    <h2>You are not allowed to access this page</h2>
  </div>
</template>

<script>

export default {
  name : 'forbidden',
};

</script>

<style scoped>

</style>
