import Vue from 'vue'
import App from './App.vue'

import './styles/bootstrap';
import createRouter from './routing/create-router';
import createStore from './state/create-store';
import createDomain from './domain/create-domain'

import config from './config/config.js';
import {Auth0Plugin, getInstance} from './auth';

// Authentication
Vue.use(Auth0Plugin, {
  domain: config.auth.domain,
  clientId: config.auth.clientId,
  audience: config.auth.audience,
  onRedirectCallback: (appState) => {
    if (appState && appState.targetUrl !== window.location.pathname) {
      router.push(
          appState && appState.targetUrl
              ? appState.targetUrl
              : window.location.pathname,
      );
    }
  },
});

// Domain
const domain = createDomain(config.expensesApiUrl);

// Store
const store = createStore(domain, getInstance());

// Router
const router = createRouter(store);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
