import ExpensesRepository from './repositories/expenses-repository';
import ExpensesApiClient from './http/expenses-api-client';
import AuthorizationService from './services/authorization-service';

export default function createDomain(
    expensesApiUrl,
)
{
    const expensesApiClient = new ExpensesApiClient(expensesApiUrl);

    return {
        expensesRepository : new ExpensesRepository(expensesApiClient),
        authorizationService : new AuthorizationService()
    };
}
