<template>
  <div id="app">
    <nav class="navbar navbar-light bg-light">
      <a class="navbar-brand" href="#">
        <router-link to="/">
          <img class="logo" src="../../assets/logo.png" width="50" height="50">
          <a class="navigation-item name">Expenses</a>
        </router-link>
        <router-link v-if="$auth.isAuthenticated" to="/dashboard" class="nav-item navigation-item active">Dashboard</router-link>
        <router-link v-if="$auth.isAuthenticated" to="/expenses" class="nav-item navigation-item active">Manage</router-link>
        <div class="buttons">
          <!-- Check that the SDK client is not currently loading before accessing the methods -->
          <div v-if="!$auth.loading">
            <!-- show login when not authenticated -->
            <a v-if="!$auth.isAuthenticated" @click="login" class="button navigation-item"
            ><strong>Sign in</strong></a
            >
            <!-- show logout when authenticated -->
            <a v-if="$auth.isAuthenticated" @click="logout" class="button navigation-item"
            ><strong>Log out</strong></a>
          </div>
        </div>
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  methods: {
    login() {
        this.$auth.loginWithRedirect();
    },
    logout() {
        this.$auth.logout({
            returnTo: window.location.origin
        });
    }
  }
}
</script>

<style>
.logo {
  margin-left: 10px;
  float: left;
}
.navbar-brand{
  width: 100%;
}
.navigation-item {
  margin: 10px;
  text-decoration: none;
  color: inherit;
  float: left;
  color: black;
}
.button {
  display: inline;
  float: right;
}
</style>
