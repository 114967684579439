<template>
  <div class="main">
    <b-container>
      <div class="card" v-if="isLoading">
        <spinner />
      </div>
      <div v-if="!isLoading">
        <b-table
          class="expenses-table"
          :items="getExpenses"
          :fields="fields"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          ref="expensesTable"
          striped
          sticky-header
          no-border-collapse
          reponsive="xs"
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
          >
        </b-table>
        <div v-if="!isAddingExpense && !showDetails && canEditExpenses">
          <button
            type="button"
            class="btn btn-outline-secondary add-expense-button"
            v-on:click="addExpense">
            Add new expense
          </button>
        </div>
        <div class="expense-details" v-if="showDetails || isAddingExpense">
          <div v-if="showDetails">
            <edit-expense :expense="{...selectedExpense}" />
          </div>
          <div v-if="isAddingExpense && !showDetails">
            <edit-expense />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import EditExpense from './EditExpense.vue';
import { mapActions, mapGetters } from 'vuex';
import Spinner from "./partials/Spinner";

export default {
  name: 'ManageExpenses',

  components: {
    'edit-expense': EditExpense,
    'spinner': Spinner
  },

  computed: {
    ...mapGetters('user', [
        'canManageExpenses'
    ]),
    ...mapGetters('expenses', [
        'getExpenses'
    ])
  },

  methods: {
    ...mapActions('expenses', [
        'loadExpenses',
        'loadCategories'
    ]),

    onRowSelected(expense) {
      if (expense.length === 1) {
        this.selectedExpense = expense[0];
        this.showDetails = true;
      } else {
        this.showDetails = false;
      }
      this.isAddingExpense = false;
    },
    addExpense() {
      this.isAddingExpense = true;
    },
    clearSelected() {
      this.isAddingExpense = false;
      this.$refs.expensesTable.clearSelected();
    },
  },

  data() {
    return {
      isLoading: true,
      sortBy: "PaymentDay",
      sortDesc: false,
      fields: [
        { key: 'Name', sortable: false },
        { key: 'Category', sortable: true },
        { key: 'PaymentDay', sortable: true },
        { key: 'Cost', sortable: true }
      ],
      showDetails: false,
      isAddingExpense: false,
      selectedExpense: {},
      canEditExpenses: false
    }
  },

  async created() {
    try {
      this.canEditExpenses = await this.canManageExpenses;

      // TODO(pedrolo): Handle possible loading errors
      await this.loadExpenses();
      await this.loadCategories();
      this.isLoading = false;
    } catch (err) {
      this.expenses = [];
      this.isError  = true;
      console.log(err);
    } finally {
      // TODO(pedrolo): Add some loading logic?
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  margin: 1%;
}

.expenses-table {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.2rem !important;
}

::v-deep .sr-only {
  display:none !important
}

.b-table-sticky-header {
  margin-bottom: 0;
  max-height: 500px;
}

.prop-name {
  font-weight: bold;
}

.card {
  height:500px;
}

.add-expense-button {
  width:100%;
  border-color: rgba(0, 0, 0, 0.200);
}

.expense-details {
  margin-top: 2%;
}
</style>
