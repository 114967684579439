<template>
    <apexchart :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'LineChart',
  data() {
    return {
      series: [
        {
          name: "Fixed",
          data: [1125, 1120, 1120, 1145, 1405, 1405]
        },
        {
          name: "Adhoc",
          data: [500, 300, 450, 500, 600, 800]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: ['#008FFB', '#FF4560'],
        title: {
          floating: false,
          text: 'Monthly Expenses Trend',
          align: 'left',
          style: {
            fontSize: '18px',
          }
        },
        grid: {
          show: true,
        },
        xaxis: {
          type: 'datetime',
          categories: ['01/01/2022 GMT', '02/01/2022 GMT', '03/01/2022 GMT', '04/01/2022 GMT', '05/01/2022 GMT', '06/01/2022 GMT'],
        },
        yaxis: {
          forceNiceScale: true,
          min: 0,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetY: 0,
          offsetX: 0
        }
      },
    }
  },
  components: {
      'apexchart' : VueApexCharts
  },
  methods: {
    trigoSeries(cnt, strength) {
      var data = [];
      for (var i = 0; i < cnt; i++) {
        data.push((Math.sin(i / strength) * (i / strength) + i / strength+1) * (strength*2));
      }
      return data;
    }
  }
}
</script>

<style scoped>

</style>
