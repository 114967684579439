import currency from "currency.js";

export default function expensesStore({
    expensesRepository
})
{
    return {

        state : {
            expenses   : [],
            categories : []
        },

        getters : {
            getExpenses (state) {
                return state.expenses;
            },
            getCategories (state) {
                return state.categories;
            }
        },

        actions : {
            async loadExpenses({ commit })
            {
                try {
                    const result = await expensesRepository.getExpenses();

                    if (result.errors) {
                        return result;
                    }

                    // Convert cost to money format
                    result.expenses.map(e => {
                        e.Cost = convertCostToMoney(e.Cost);
                    })

                    commit('expensesLoaded', { expenses : result.expenses });

                    return result.expenses;
                } catch (error) {
                    console.log(`Failed to fetch expenses. Error: ${error}`);
                    
                    throw error;
                }
            },

            async loadCategories({ commit })
            {
                try {
                    const result = await expensesRepository.getCategories();

                    if (result.errors) {
                        return result;
                    }

                    commit('categoriesLoaded', { categories : result.categories });

                    return result;
                } catch (error) {
                    console.log(`Failed to fetch categories. Error: ${error}`);

                    throw error;
                }
            },

            async createExpense({ commit }, expense)
            {
                try {
                    expense.Cost = convertCostToInteger(expense.Cost); 

                    const result = await expensesRepository.createExpense(expense);

                    if (result.errors) {
                        return result;
                    }

                    // Convert cost to money format
                    result.expense.Cost = convertCostToMoney(result.expense.Cost);

                    commit('expenseCreated', { expense : result.expense });

                    return result.expense;
                } catch (error) {
                    console.log(`Failed to create expense. Error: ${error}`);

                    throw error;
                }
            },

            async updateExpense({ commit }, expense)
            {
                try {
                    expense.Cost = convertCostToInteger(expense.Cost);

                    const result = await expensesRepository.updateExpense(expense);

                    if (result.errors) {
                        return result;
                    }

                    // Convert cost to money format
                    result.expense.Cost = convertCostToMoney(result.expense.Cost);

                    commit('expenseUpdated', { expense : result.expense });

                    return result.expense;
                } catch (error) {
                    console.log(`Failed to update expense. Error: ${error}`);

                    throw error;
                }
            },

            async deleteExpense({ commit }, expenseId)
            {
                try {
                    const result = await expensesRepository.deleteExpense(expenseId);

                    if (!result.errors) {
                        commit('expenseDeleted', expenseId)
                    }
                } catch (error) {
                    console.log(`Failed to delete expense. Error: ${error}`);

                    throw error;
                }
            }
        },

        mutations : {
            expensesLoaded(state, { expenses }) {
                state.expenses = expenses;
            },

            categoriesLoaded(state, { categories }) {
                state.categories = categories;
            },

            expenseCreated(state, { expense }) {
                state.expenses.push(expense);
            },

            expenseUpdated(state, { expense }) {
                const index = state.expenses.findIndex(e => e.Id === expense.Id);
                state.expenses.splice(index, 1, expense);
            },

            expenseDeleted(state, { expenseId }) {
                const index = state.expenses.findIndex(e => e.Id === expenseId);
                state.expenses.splice(index, 1);
            }
        },

        namespaced : true
    };
}

// Utils
function convertCostToMoney(cost) {
    return currency(cost, { fromCents: true, precision: 2 }).value;
}

function convertCostToInteger(cost) {
    return currency(cost, { precision: 2 }).intValue;
}
