<template>
    <apexchart :options="chartOptions" :series="this.chartData"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DonutChart',
  props: {
    data: {
        type: Array
    }
  },
  components: {
      'apexchart' : VueApexCharts
  },
  computed: {
    chartLabels: function() {
      return Array.from(new Set(this.data.map(e => e.Category)));
    },
    chartData: function() {
      const categories = this.chartLabels;
      let totals = new Array(categories.length).fill(0.00);
      this.data.forEach(e => {
        const index = categories.indexOf(e.Category);
        totals[index] += e.Cost;
      });

      return totals.map(t => parseFloat(t.toFixed(2)));
    },

    chartOptions: function() {
      return {
        labels: this.chartLabels,
        chart: {
          type: 'donut',
          height: '10%'
        },
        title: {
          floating: false,
          text: 'Fixed Monthly Expenses',
          align: 'left',
          style: {
            fontSize: '18px',
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetY: 0,
          offsetX: 0
        },
        responsive: [
          {
            breakpoint: 4000,
            options: {
              chart: {
                width: '100%',
                height: 300
              },
              legend: {
                position: 'top',
              }
            }
          },
        ],
        plotOptions: {
          pie: {
            offsetY: 0,
            customScale: 1,
            donut: {
              size: '60%',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Total',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  // formatter: function (w) {
                  //   return w.globals.seriesTotals.reduce((a, b) => {
                  //     return a + b
                  //   }, 0)
                  // }
                }
              }
            }
          },
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
