<template>
    <apexchart :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'BarChart',
  data() {
    return {
      series: [{
        name: 'house',
        data: [1000, 1000, 1000, 1000, 1250, 1250]
      }, {
        name: 'utility',
        data: [75, 80, 80, 95, 95, 95]
      }, {
        name: 'other',
        data: [50, 40, 40, 50, 60, 60]
      }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        title: {
          floating: false,
          text: 'History Fixed Monthly Expenses',
          align: 'left',
          style: {
            fontSize: '18px',
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10
          },
        },
        xaxis: {
          type: 'datetime',
          categories: ['01/01/2022 GMT', '02/01/2022 GMT', '03/01/2022 GMT', '04/01/2022 GMT', '05/01/2022 GMT', '06/01/2022 GMT'],
        },
        yaxis: {
          forceNiceScale: true,
          min: 0,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetY: 0,
          offsetX: 0
        }
      },
    }
  },
  components: {
      'apexchart' : VueApexCharts
  }
}
</script>

<style scoped>

</style>
