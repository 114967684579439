<template>
  <b-card>
    <div
      class="error-banner"
      v-if="errors.length">
      <b-alert show variant="danger">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors" :key="error.id">
            {{ error }}
          </li>
        </ul>
      </b-alert>
    </div>

    <div class="form-group row">
      <label
        for="Name"
        class="col-4 col-sm-4 col-form-label prop-name">
        Name
      </label>
      <div class="col-8 col-sm-8">
        <input
          type="text"
          class="form-control"
          id="Name"
          placeholder="Enter expense name"
          :readonly="!isEditing"
          v-model="form.Name">
      </div>
    </div>
    <div class="form-group row">
      <label
        for="Cost"
        class="col-4 col-sm-4 col-form-label prop-name">
        Cost
      </label>
      <div class="col-8 col-sm-8">
        <input
          type="text"
          class="form-control"
          id="Cost"
          placeholder="Enter expense cost"
          :readonly="!isEditing"
          v-model="form.Cost">
      </div>
    </div>
    <div class="form-group row">
      <label
        for="PaymentDay"
        class="col-4 col-sm-4 col-form-label prop-name">
        Payment Day
      </label>
      <div class="col-8 col-sm-8">
        <input
          type="number"
          class="form-control"
          id="PaymentDay"
          placeholder="Enter payment day"
          min="1"
          max="31"
          :readonly="!isEditing"
          v-model="form.PaymentDay">
      </div>
    </div>
    <div class="form-group row">
      <label
        for="Category"
        class="col-4 col-sm-4 col-form-label prop-name">
        Expense Category
      </label>
      <div class="col-8 col-sm-8">
        <b-form-select
          class="form-select"
          :disabled="!isEditing"
          v-model="form.Category"
          :options="type_options"></b-form-select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="buttons">
          <div v-if="isEditing">
            <button
              type="button"
              class="btn btn-outline-success"
              v-on:click="submit">
              <Icon icon="mdi:check-circle-outline" class="button-icon" />
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary clear-button"
              v-on:click="cancel">
              <Icon icon="mdi:close-circle" class="button-icon" />
            </button>
          </div>
          <div v-if="!isEditing && canEditExpense">
            <button
                type="button"
                class="btn btn-outline-primary"
                v-on:click="edit">
              <Icon icon="mdi:circle-edit-outline" class="button-icon" />
            </button>

            <b-button
                class="clear-button"
                v-b-modal.delete-modal
                variant="outline-danger">
              <Icon icon="mdi:delete-circle-outline" class="button-icon" />
            </b-button>
            <b-modal
                id="delete-modal"
                ref="delete-modal"
                hide-header
                hide-footer>
              <p class="my-3 text-center">Delete the expense "{{expense.Name}}" with category "{{expense.Category}}?"</p>
              <div class="buttons">
                <b-button class="mh-3" variant="outline-danger" block @click="deleteSelectedExpense">
                  <Icon icon="mdi:delete-circle-outline" class="button-icon" />
                </b-button>
                <b-button class="mh-3" variant="outline-secondary clear-button" block @click="hideModal">
                  <Icon icon="mdi:close-circle" class="button-icon" />
                </b-button>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Icon } from '@iconify/vue2';
import currency from "currency.js";

export default {
  name: 'EditExpense',
    props: {
      expense: {
        type: Object
      }
  },
  
  components : {
    Icon
  },

  watch: {
    expense: function() {
      this.isEditing = false;
    }
  },
  
  async created() {
    this.canEditExpense = await this.canManageExpenses;
    const categories = this.getCategories;
    categories.forEach(category => this.type_options.push({value: category, text: category}));
  },

  data() {
    return  {
      isEditing : this.expense == null,
      errors : [],
      type_options : [
        { value: null, text: 'Select expense category' }
      ],
      canEditExpense : false
    }
  },

  computed: {
    form() {
      return this.expense ||
        {
          Name: '',
          Cost: '',
          PaymentDay: '',
          Category: null
        };
    },
    ...mapGetters('user', [
        'canManageExpenses'
    ]),
    ...mapGetters('expenses', [
        'getCategories'
    ])
  },

  methods: {
    ...mapActions('expenses', [
      'createExpense',
      'updateExpense',
      'deleteExpense'
    ]),

    isValid() {
      this.errors = [];

      if (!this.form.Name) {
        this.errors.push('Name required.');
      }

      if (!this.form.Cost ||
          this.form.Cost <= 0) {
        this.errors.push('Cost must be greater than 0.');
      }
      
      if (this.form.Cost && Number.isNaN(currency(this.form.Cost).value)) {
        this.errors.push('Cost must be a valid monetary value.');
      }

      if (!this.form.PaymentDay ||
          this.form.PaymentDay < 0 ||
          this.form.PaymentDay > 31) {
        this.errors.push('Payment day must be between 1 and 31.')
      }

      if (!this.form.Category) {
        this.errors.push('Category required.');
      }

      return !this.errors.length;
    },

    async submit() {
      if (this.isValid()) {
        console.log('submitting');
        const expense = {
          Id: this.expense ? this.expense.Id : "",
          Name: this.form.Name,
          Cost: this.form.Cost,
          PaymentDay: parseInt(this.form.PaymentDay),
          Category: this.form.Category
        };

        // TODO(pedrolo): Handle possible errors
        if (expense.Id) {
          await this.updateExpense(expense);
        } else {
          await this.createExpense(expense);
        }

        this.cancel();
      }
    },

    cancel() {
      this.$parent.clearSelected();
    },

    edit() {
      this.isEditing = true;
    },

    async deleteSelectedExpense() {
      await this.deleteExpense(this.expense.Id);

      this.cancel();
    },

    hideModal() {
      this.$refs['delete-modal'].hide();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-group {
  margin: 1%;
}
.prop-name {
  font-weight: bold;
}
.buttons {
  padding-top: 1%;
  float: right;
}
.clear-button {
  margin-left: 10px;
}
.button-icon {
  width:20px;
  height:20px;
}
.alert {
  padding: 15px;
  text-align: left;
  margin: 0 auto;
}
ul {
  margin-bottom: 0;
}
.error-banner {
  padding: 5px;
}
</style>
