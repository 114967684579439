<template>
  <div class="main">
    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-5">
        <div class="card">
          <div class="spinner-box" v-if="isLoading">
            <spinner />
          </div>
          <div v-if="!isLoading">
            <donut-chart :data=this.getExpenses></donut-chart>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-5">
        <div class="card">
          <div class="spinner-box" v-if="isLoading">
            <spinner />
          </div>
          <div v-if=!isLoading >
            <h3 class="title">Upcoming expenses</h3>
            <b-table
                class="upcoming-expenses-table"
                style="font-family: Helvetica, Arial, sans-serif"
                :items="this.upcomingExpenses"
                :fields="tableFields"
                ref="upcomingExpenses"
                borderless
                reponsive="xs"
            >
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-5">
        <div class="card">
          <line-chart></line-chart>
        </div>
      </div>
      <div class="col-md-12 col-lg-5">
        <div class="card">
          <bar-chart></bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DonutChart from './DonutChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import Spinner from "./partials/Spinner";

export default {
  name: 'Dashboard',
    components: {
    'donut-chart' : DonutChart,
    'bar-chart' : BarChart,
    'line-chart': LineChart,
    'spinner' : Spinner
  },

  data() {
    return {
      isLoading: true,
      tableFields: [
          'Name',
          'PaymentDay',
          'Cost'
      ]
    }
  },

  async created() {
    try {
      // TODO(pedrolo): Handle possible loading errors
      await this.loadExpenses();
      await this.loadCategories();
      this.isLoading = false;
    } catch (err) {
      this.isError  = true;
      console.log(err);
    } finally {
      // TODO(pedrolo): Add some loading logic?
    }
  },

  computed: {
    ...mapGetters('expenses', [
      'getExpenses',
      'getCategories'
    ]),
    
    upcomingExpenses: function() {
      const today = new Date().getDate();
      const expenses = this.getExpenses; 
      const sorted = expenses.sort((a,b) => a.PaymentDay - b.PaymentDay);

      const over = sorted.filter(e => e.PaymentDay >= today);
      const under = sorted.filter(e => e.PaymentDay < today);

      return over.concat(under).slice(0,5);
    }
  },

  methods: {
    ...mapActions('expenses', [
      'loadExpenses',
      'loadCategories'
    ]),
  }
}
</script>

<style scoped>
.main {
  margin: 1%;
}

.card {
  height:100%;
}

.col-sm-12 {
  margin-bottom: 1%;
}

.col-md-12 {
  margin-bottom: 1%;
}

.title {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  margin-top: 3px;
  margin-left: 10px;
}

.spinner-box {
  height: 300px;
}

/*.b-table-sticky-header {*/
/*  margin-bottom: 0;*/
/*  max-height: 500px;*/
/*}*/

</style>