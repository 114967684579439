<template>
  <div v-if="$auth.isAuthenticated">
      <h2>Welcome to your recurring expenses!</h2>
  </div>
</template>

<script>
export default {
    name: 'Home',
}
</script>

<style>
h2{
    padding-top: 10px;
}
</style>